import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

/* Layout */
import Layout from "@/layout";

/**
 * Note: sub-menu only appear when route children.length >= 1  注意：子菜单仅在route children.length>=1时出现
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false) // 当设置 true 的时候该路由不会在侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 *                                // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由

 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb //当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             the name is used by <keep-alive> (must set!!!) // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles) // 设置该路由进入的权限，支持多个权限叠加
    title: 'title'               the name show in sidebar and breadcrumb (recommend set) // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             the icon show in the sidebar  // 设置该路由的图标，支持 svg-class，也支持 el-icon-x element-ui 的 icon
    noCache: true                if set true, the page will no be cached(default is false) // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    affix: true                  if set true, the tag will affix in the tags-view // 如果设置为true，它则会固定在tags-view中(默认 false)
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)//  如果设置为false，则不会在breadcrumb面包屑中显示(默认 true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
                                  // 当路由设置了该属性，则会高亮相对应的侧边栏。
                                  // 这在某些场景非常有用，比如：一个文章的列表页路由为：/article/list
                                  // 点击文章进入文章详情页，这时候路由为/article/1，但你想在侧边栏高亮文章列表的路由，就可以进行如下设置
                                  activeMenu: '/article/list'
  }
 */

// 所有权限通用路由表
// 如首页和登录页和一些不用权限的公用页面

export var constantRouterMap = [{
  path: "/login",
  component: function component() {
    return import("@/views/login/index");
  },
  hidden: true,
  meta: {
    whiteMenu: true
  }
}, {
  path: "/",
  component: Layout,
  redirect: "/dashboard",
  name: "Dashboard",
  hidden: true,
  meta: {
    whiteMenu: true
  },
  children: []
}, {
  path: "/1",
  component: Layout,
  name: "运维管理",
  hidden: true,
  children: [{
    path: '/taskDetail',
    component: function component() {
      return import('@/views/taskDetail/index');
    },
    name: 'taskDetail',
    hidden: true,
    meta: {
      title: '任务详情',
      whiteMenu: false
    }
  }, {
    path: '/nodeDetail',
    component: function component() {
      return import('@/views/nodeDetail/index');
    },
    name: 'nodeDetail',
    hidden: true,
    meta: {
      title: '节点详情',
      whiteMenu: false
    }
  }, {
    path: '/bindUser',
    component: function component() {
      return import('@/views/bindUser/index');
    },
    name: 'bindUser',
    hidden: true,
    meta: {
      title: '用户绑定',
      whiteMenu: false
    }
  }, {
    path: '/package',
    component: function component() {
      return import('@/views/package/index');
    },
    name: 'package',
    hidden: true,
    meta: {
      title: '队列管理',
      whiteMenu: false
    }
  }]
}, {
  path: "/preview",
  component: function component() {
    return import("@/views/preview");
  },
  hidden: true,
  meta: {
    whiteMenu: true
  }
}, {
  path: "/404",
  component: function component() {
    return import("@/views/404");
  },
  hidden: true,
  meta: {
    whiteMenu: true
  }
}

// 404 page must be placed at the end !!!
// { path: "*", redirect: "/404", hidden: true, meta: { whiteMenu: true } },
];
var originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};

// 实例化vue的时候只挂载constantRouter
export default new Router({
  // mode: 'history',
  routes: constantRouterMap
});