export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "default"
    }
  },
  data: function data() {
    return {
      time: null
    };
  },
  methods: {
    handleClick: function handleClick() {
      if (this.time && new Date().getTime() - this.time < 500) {
        return;
      }
      this.time = new Date().getTime();
      !this.loading && !this.disabled && this.$emit("confirm");
    }
  }
};