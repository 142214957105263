import Vue from 'vue';
import SvgIcon from '@/components/SvgIcon'; // svg component

// register globally
Vue.component('svg-icon', SvgIcon);
var req = require.context('./svg', false, /\.svg$/);
var iconList = req.keys().map(function (item) {
  return req(item);
});
var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
};
requireAll(req);
export default {
  // 获取图标icon-(*).svg名称列表
  getNameList: function getNameList() {
    return iconList.map(function (item) {
      return item.default.id.replace('icon-', '');
    });
  }
};