import Cookies from 'js-cookie';
var myTokenKey = 'managerToken';
var includesApps = 'managerAllowApps';
var domainUrl = envConfig.VUE_APP_DOMAIN || process.env.VUE_APP_DOMAIN;
export function getToken() {
  return Cookies.get(myTokenKey);
}
export function setToken(token) {
  return Cookies.set(myTokenKey, token);
}
export function setAllowApps(t) {
  return Cookies.set(includesApps, t);
}
export function removeToken() {
  // return Cookies.remove(myTokenKey)
  // Cookies.remove('mytoken', { domain: domainUrl })
  // Cookies.remove('allowApps', { domain: domainUrl })
  Cookies.remove(myTokenKey);
  Cookies.remove(includesApps);
}