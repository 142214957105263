var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  menu: function menu(state) {
    return state.user.menu;
  },
  userInfo: function userInfo(state) {
    return state.user.userInfo;
  },
  task: function task(state) {
    return state.user.task;
  }
};
export default getters;