// 公告发布页面
import request from '@/utils/request';
var baseUrl2 = envConfig.VUE_APP_ucApi || process.env.VUE_APP_ucApi;

// 公告发布
export function notice(data) {
  return request({
    url: "".concat(baseUrl2, "/message/notice"),
    method: 'post',
    data: data
  });
}

// 查询公告列表接口
export function noticeFilter(params) {
  return request({
    url: "".concat(baseUrl2, "/message/filter"),
    method: 'get',
    params: params
  });
}

// 获取用户信息
export function userInfo(params) {
  return request({
    url: "".concat(baseUrl2, "/usercenter/user"),
    method: 'get',
    params: params
  });
}