import Layout from "@/layout";
// 路由映射
var routeMap = [{
  name: 'Dashboard',
  meta: {
    icon: "dashboard-nav"
  }
}, {
  name: '用户管理',
  meta: {
    icon: "user-nav"
  }
}, {
  name: '运营者管理',
  meta: {
    icon: "manager-nav"
  }
}, {
  name: '运维管理',
  meta: {
    icon: "experiment-nav"
  }
}, {
  name: '套餐与费用',
  meta: {
    icon: "comment-nav"
  }
}, {
  name: '公告管理',
  meta: {
    icon: "notice-nav"
  }
}, {
  name: '字典管理',
  meta: {
    icon: "dic-nav"
  }
}, {
  name: '工单管理',
  meta: {
    icon: "work-order-nav"
  }
}, {
  name: '配置管理',
  meta: {
    icon: "comment-nav"
  }
}, {
  name: '账单管理',
  meta: {
    icon: "bill-nav"
  }
}];
// const routerPath = [
//     {path:'/dashboard',component:() => import("@/views/dashboard/index"),meta: { title: "Dashboard", icon: "dashboard-nav", whiteMenu: true }},
//     {path:'/user',component:() => import('@/views/user/index'), meta: { title: '注册用户', whiteMenu: false }},
//     {path:'/audit',component:() => import('@/views/audit/index'),meta: { title: '审核管理', whiteMenu: false }},
//     {path:'/manager',component:() => import('@/views/manager/index'),meta: { title: '运营人员管理', whiteMenu: false }},
//     {path:'/role',component:() => import('@/views/role/index'), meta: { title: '角色管理', whiteMenu: false }},
//     {path:'/permission',component: () => import('@/views/permission/index'), meta: { title: '权限管理', whiteMenu: false }},
//     {path:'/logs',component: () => import('@/views/logs/index'), meta: { title: '操作日志', whiteMenu: false }},
//     {path:'/calculateForce',component: () => import('@/views/calculateForce/index'),meta: { title: '算力套餐管理', whiteMenu: false }},
//     {path:'/payRecords',component: () => import('@/views/payRecords/index'),meta: { title: '算力收支管理', whiteMenu: false }},
//     {path:'/announcement',component: () => import('@/views/announcement/index'),meta: { title: '公告发布', whiteMenu: false }}, 
//     {path:'/taskManagement',component: () => import('@/views/taskManagement/index'),meta: { title: '任务管理', whiteMenu: false }}, 
//     {path:'/nodeManagement',component: () => import('@/views/nodeManagement/index'),meta: { title: '节点管理', whiteMenu: false }},
//     {path:'/logs',component: () => import('@/views/logs/index'),meta: { title: '操作日志', whiteMenu: false }},
//     {path: '/image',component: () => import('@/views/image/index'),meta: { title: '官方镜像', whiteMenu: false }},
//     {path: '/alarm',component: () => import('@/views/alarm/index'),meta: { title: '告警信息', whiteMenu: false }},
//     {path: '/operation',component: () => import('@/views/operation/index'),meta: { title: '运维策略', whiteMenu: false }},
//     {path: '/classification',component: () => import('@/views/classification/index'),meta: { title: '数据集分类', whiteMenu: false }},
//     {path: '/configure',component: () => import('@/views/configure/index'),meta: { title: '配置文件', whiteMenu: false }},
// ]
// 处理动态左侧菜单
export function generaMenu(routes, data) {
  return new Promise(function (resolve, reject) {
    data.forEach(function (item) {
      // 缓存中不存在url是path需要特殊处理
      // item.url == '' ? Layout : () => import(`@/views${item.url}/index`)
      var menu = {
        path: !item.url ? "/".concat(item.id) : item.url,
        component: !item.url ? Layout : function (resolve) {
          return require(["@/views".concat(item.url, "/index")], resolve);
        },
        children: [],
        name: item.name,
        meta: {
          title: item.name,
          icon: "",
          id: item.id
        }
      };
      if (!item.url) {
        // 一级菜单需要配置icon
        var cur = routeMap.find(function (msg, index) {
          return msg.name == item.name;
        });
        if (cur) {
          menu.meta.icon = cur.meta.icon;
        }
        // menu.component = Layout;
      } else {
        // 二级菜单需要配置path对应的地址
        // var curPath = routeMap.find((res,index)=>{
        //     return res.path == item.url
        // })
        // menu.component = curPath.component;
        if (item.children.length == 0) {
          delete menu.children;
        }
      }
      if (item.children) {
        generaMenu(menu.children, item.children);
      }
      routes.push(menu);
    });
    resolve(routes);
  });
}