var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-pagination", {
    staticClass: "admin-pagination",
    attrs: {
      background: "",
      layout: "total, prev, pager, next",
      "current-page": _vm.pageData.pageIndex,
      "page-size": _vm.pageData.pageSize,
      total: _vm.total
    },
    on: {
      "current-change": _vm.handleCurrentChange
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };