export default {
  props: ["value", "total"],
  data: function data() {
    return {
      pageData: {
        pageNumber: 0,
        pageIndex: 0,
        pageSize: 10
      }
    };
  },
  watch: {
    value: {
      handler: function handler(v) {
        var _this = this;
        Object.keys(v).forEach(function (vKey) {
          _this.$set(_this.pageData, vKey, v[vKey]);
        });
      },
      immediate: true,
      deep: true
    }
  },
  model: {
    event: "change"
  },
  methods: {
    handleCurrentChange: function handleCurrentChange(value) {
      !this.pageData.pageNumber && delete this.pageData["pageNumber"] && this.$set(this.pageData, "pageIndex", value);
      !this.pageData.pageIndex && delete this.pageData["pageIndex"] && this.$set(this.pageData, "pageNumber", value);
      this.$emit("change", this.pageData);
    }
  }
};