var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-button", {
    attrs: {
      type: _vm.type,
      loading: _vm.loading,
      disabled: _vm.loading || _vm.disabled,
      size: _vm.size
    },
    on: {
      click: _vm.handleClick
    }
  }, [_vm._t("default")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };