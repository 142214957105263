function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { login as _login, loginOut } from '@/api/userManage';
import { getMenu as _getMenu } from '@/api/manage';
import { userInfo } from '@/api/announcement';
import { getToken, removeToken, setToken, setAllowApps } from '@/utils/auth';
import { generaMenu, generaStorageMenu } from '@/utils/menu';
import MyStorage from '@/utils/cache';
import Cookies from 'js-cookie';
import router from '../../router';
import { Message } from 'element-ui';
var nTime = new Date().getTime();
var state = {
  token: getToken(),
  name: '',
  avatar: '',
  userInfo: MyStorage.getItem('userInfo') || '',
  // menu: MyStorage.getItem('menu') || [],
  menu: [],
  task: [] //任务详情下子任务信息
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_USERINFO: function SET_USERINFO(state, userInfo) {
    state.userInfo = userInfo;
    MyStorage.setItem('userInfo', userInfo);
  },
  SET_MENU: function SET_MENU(state, menu) {
    state.menu = menu;
    MyStorage.setItem('menu', menu);
  },
  SET_TASK: function SET_TASK(state, task) {
    state.task = task;
  }
};
var actions = {
  // 登录
  login: function login(_ref, paramObj) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      _login(paramObj).then(function (response) {
        var data = response.data,
          message = response.message;
        if (message.code === 0 && data) {
          commit('SET_TOKEN', data.token.token);
          commit('SET_USERINFO', data.userInfo);
          commit('SET_NAME', data.userInfo.account);
          setToken(data.token.token);
          setAllowApps(JSON.stringify(data.allowApps));
        }
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get Menu
  getMenu: function getMenu(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      var params = {
        appClientId: envConfig.VUE_APP_clientId || process.env.VUE_APP_clientId
      };
      _getMenu(params).then(function (response) {
        var data = response.data,
          message = response.message;
        if (message.code !== 0) {
          if (nTime && new Date().getTime() - nTime < 500) {
            return;
          }
          nTime = new Date().getTime();
          Message.error({
            message: message.message
          });
          return;
        }
        if (!data.children) {
          Message.error({
            message: "请联系管理员配置角色"
          });
          return;
        }
        //   data.children = []
        if (message.code === 0 && data.children) {
          if (!data.children.length) {
            Message.error({
              message: "请联系管理员配置权限"
            });
          }
          var routesList = _toConsumableArray(router.options.routes);
          // 将常用路由和异步路由合并
          generaMenu(routesList, data.children).then(function (res) {
            commit('SET_MENU', res);
            resolve(res);
          });
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      var params = {};
      loginOut(params).then(function (res) {
        removeToken();
        commit('SET_TOKEN', '');
        commit('SET_USERINFO', '');
        commit('SET_MENU', '');
        MyStorage.clear();
      });
      // removeToken()
      // commit('SET_TOKEN', '')
      // commit('SET_USERINFO', '')
      // commit('SET_MENU', '')
      // MyStorage.clear();
      router.push({
        path: '/login'
      });
      // window.location.href = `${process.env.VUE_APP_ucUrl}/login?clientId=${process.env.VUE_APP_clientId}&redirectUrl=${window.location.origin}/#/`;
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      removeToken();
      resolve();
    });
  },
  // 获取用户信息
  getUserInfo: function getUserInfo(_ref5) {
    var commit = _ref5.commit,
      state = _ref5.state;
    var params = {
      appClientId: envConfig.VUE_APP_clientId || process.env.VUE_APP_clientId
    };
    userInfo(params).then(function (res) {
      if (res.message.code == 0) {
        commit('SET_USERINFO', res.data);
      }
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};