export default {
  props: {
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否存在下划线
    underline: {
      type: Boolean,
      default: false
    },
    // 标题
    title: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "primary"
    }
  },
  methods: {
    // 确认
    handleConfirm: function handleConfirm() {
      this.$emit("confirm");
    },
    // 点击
    handleClick: function handleClick() {
      !this.disabled && this.$emit("click");
    }
  }
};