var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ai-link-tel"
  }, [!_vm.disabled && _vm.title ? _c("el-popconfirm", {
    attrs: {
      title: _vm.title
    },
    on: {
      confirm: _vm.handleConfirm
    }
  }, [_c("el-link", {
    attrs: {
      slot: "reference",
      type: _vm.type,
      underline: _vm.underline
    },
    slot: "reference"
  }, [_vm._t("default")], 2)], 1) : _c("el-link", {
    staticClass: "one-line",
    attrs: {
      slot: "reference",
      type: _vm.type,
      disabled: _vm.disabled,
      underline: _vm.underline
    },
    on: {
      click: _vm.handleClick
    },
    slot: "reference"
  }, [_vm._t("default")], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };