/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}
var REGCONST = {
  // 不支持表情
  emoji: /^[^\ud83c-\udfff\udc00-\udfff\u2000-\u2fff\u2600-\u27ff]*$/
};

// 表情输入校验
var validateEmoji = function validateEmoji(rule, value, callback) {
  var result = REGCONST.emoji.test(value);
  if (!result) {
    callback(new Error("不支持表情符号"));
  } else {
    callback();
  }
};
export { validateEmoji };