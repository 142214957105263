/**
 * @author zhayuechao@leinao.ai
 * @date 2021/4/19
 * @description global components
 */
import AiPagination from "./ai-pagination";
import AiLink from "./ai-link";
import AiButton from "./ai-button";
var components = {
  AiPagination: AiPagination,
  AiLink: AiLink,
  AiButton: AiButton
};
export default {
  install: function install(Vue) {
    Object.keys(components).forEach(function (name) {
      Vue.component(name, components[name]);
    });
  }
};