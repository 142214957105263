import request from '@/utils/request';
//订单
var baseUrl2 = "".concat(envConfig.VUE_APP_console || process.env.VUE_APP_console, "/gateway/systemBackend/api");
var baseUrl = "".concat(envConfig.VUE_APP_ucApi || process.env.VUE_APP_ucApi);

// 退出登录的接口
export function loginOut(data) {
  return request({
    url: "".concat(baseUrl, "/usercenter/logout?appClientId=").concat(envConfig.VUE_APP_clientId || process.env.VUE_APP_clientId),
    method: 'post',
    data: data
  });
}

// 登录接口
export function login(data) {
  return request({
    url: "".concat(baseUrl, "/usercenter/login"),
    method: 'post',
    data: data
  });
}
export function haveRole(data) {
  return request({
    url: "".concat(baseUrl2, "/user/filter/page/haveRole"),
    method: 'post',
    data: data
  });
}

// 充值算力接口
export function giveUserTime(data) {
  return request({
    url: "".concat(baseUrl2, "/calculationPower/giveUserTime"),
    method: 'post',
    data: data
  });
}

// 运营人员管理页面一览接口
export function manageHaveRole(data) {
  return request({
    url: "".concat(baseUrl, "/user/filter/page/byRoleIds"),
    method: 'post',
    data: data
  });
}

// 账号停用和启用
export function updateManagerStatus(data) {
  return request({
    url: "".concat(baseUrl, "/manager/batch/update"),
    method: 'post',
    data: data
  });
}

// 查询审核管理列表
export function getDeveloperList(data) {
  return request({
    url: "".concat(baseUrl, "/apply/filter"),
    method: 'post',
    data: data
  });
}

// 开发者账号审核
export function auditAccount(data) {
  return request({
    url: "".concat(baseUrl, "/apply"),
    method: 'put',
    data: data
  });
}

// 获取所有的应用
export function getAllClients(params) {
  return request({
    url: "".concat(baseUrl, "/oauth/clients"),
    method: 'get',
    params: params
  });
}

// 内部后端注册用户
export function registerInside(data) {
  return request({
    url: "".concat(baseUrl, "/usercenter/registerInside"),
    method: 'post',
    data: data
  });
}

// 给用户绑定应用
export function userBindApp(data, id) {
  return request({
    url: "".concat(baseUrl, "/manager/userBindApp?userId=").concat(id),
    method: 'post',
    data: data
  });
}

// 给用户绑定应用
export function unBindApp(data) {
  return request({
    url: "".concat(baseUrl, "/oauth/unbind/clients"),
    method: 'post',
    data: data
  });
}

// 删除用户
export function deleteUser(data) {
  return request({
    url: "".concat(baseUrl, "/manager/user").concat(data),
    method: 'delete'
  });
}
export function exportExcel(data) {
  return request({
    url: "".concat(baseUrl2, "/user/export"),
    method: 'post',
    data: data,
    responseType: 'blob',
    isReturnNativeResponse: true
  });
}

// 修改用户等级接口
export function updateLevel(data) {
  return request({
    url: "".concat(baseUrl2, "/user/level/update"),
    method: 'post',
    data: data
  });
}

// 学院设置
export function updatePosition(data) {
  return request({
    url: "".concat(baseUrl, "/usercenter/position/update"),
    method: 'post',
    data: data
  });
}