import request from '@/utils/request';
var baseUrl2 = envConfig.VUE_APP_ucApi || process.env.VUE_APP_ucApi;
export function getLimitList(data) {
  return request({
    url: "".concat(baseUrl2, "/app/menu/page"),
    method: 'post',
    data: data
  });
}
export function addLimit(data) {
  return request({
    url: "".concat(baseUrl2, "/app/menu"),
    method: 'post',
    data: data
  });
}
export function deleteLimit(params) {
  return request({
    url: "".concat(baseUrl2, "/app/menu/").concat(params),
    method: 'delete'
  });
}

// 获取角色管理表格数据
export function getRoleByPage(data) {
  return request({
    url: "".concat(baseUrl2, "/manager/getRoleListByPage"),
    method: 'post',
    data: data
  });
}

// 新增角色
export function addRole(data) {
  return request({
    url: "".concat(baseUrl2, "/manager/addRole"),
    method: 'post',
    data: data
  });
}

// 编辑角色
export function updateRole(data) {
  return request({
    url: "".concat(baseUrl2, "/manager/updateRole"),
    method: 'post',
    data: data
  });
}

// 删除角色
export function deleteRole(data) {
  return request({
    url: "".concat(baseUrl2, "/manager/deleteRole"),
    method: 'post',
    data: data
  });
}

// 获取树形结构
export function getRoleLimit(data) {
  return request({
    url: "".concat(baseUrl2, "/app/menu/info/tree"),
    method: 'post',
    data: data
  });
}

// 绑定菜单
export function configRoleLimit(data) {
  return request({
    url: "".concat(baseUrl2, "/app/menu/bind"),
    method: 'post',
    data: data
  });
}

//根据用户权限获取对应的菜单 
export function getMenu(params) {
  return request({
    url: "".concat(baseUrl2, "/app/menu/user/tree"),
    method: 'get',
    params: params
  });
}

// 给用户配置角色
export function configUserRole(data) {
  return request({
    url: "".concat(baseUrl2, "/manager/configUserRole"),
    method: 'post',
    data: data
  });
}

// 移出运维权限
export function removeUserRole(data) {
  return request({
    url: "".concat(baseUrl2, "/manager/removeUserRole"),
    method: 'post',
    data: data
  });
}

// 查询当前应用下还没有绑定角色的用户
export function exclude(data) {
  return request({
    url: "".concat(baseUrl2, "/user/filter/page/role/exclude"),
    method: 'post',
    data: data
  });
}