function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getAdminToken, getToken } from '@/utils/auth';
import router from '@/router';

// create an axios instance
var service = axios.create({
  baseURL: envConfig.VUE_APP_BASE_API || process.env.VUE_APP_BASE_API,
  // url = base url + request url
  timeout: 60000
});

// request interceptor
service.interceptors.request.use(function (config) {
  if (store.getters.token) {
    config.headers.common['token'] = getToken();
    config.headers.Authorization = "Bearer ".concat(getToken());
    if (store.getters.userInfo) {
      config.headers.common['userName'] = store.getters.userInfo.account;
      config.headers.common['userId'] = store.getters.userInfo.id;
    }
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(function (response) {
  var res = response.data;
  if (res.message) {
    if (res.message.code !== 0) {
      if (res.message && (res.message.code === -2 || res.message.code === -20002 || res.message.code == 10133 || res.message.code == 10157)) {
        store.dispatch('user/logout');
        router.push({
          path: '/login'
        });
        // window.location.href = `${process.env.VUE_APP_ucUrl}/login?clientId=${process.env.VUE_APP_clientId}&redirectUrl=${window.location.origin}/#/`
      }
      return res;
    } else if (res.message.code === 'TOKEN_AUTH_ERROR') {
      store.dispatch('user/logout');
      router.push({
        path: '/login'
      });
      // window.location.href = `${process.env.VUE_APP_ucUrl}/login?clientId=${process.env.VUE_APP_clientId}&redirectUrl=${window.location.origin}/#/`
    } else {
      return res;
    }
  }
  if (response.config.isReturnNativeResponse) {
    return response;
  }
}, function (error) {
  if (!error.response) {
    Message({
      message: '请求失败，服务端无响应.',
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
  if (error.response.status && error.response.status === 400 && error.response.data.message) {
    Message({
      message: _typeof(error.response.data.message) === 'object' ? error.response.data.message.message : error.response.data.message,
      type: 'error',
      duration: 5 * 1000
    });
  } else if (error.response.status === 401) {
    store.dispatch('user/logout');
    location.href = "".concat(envConfig.VUE_APP_ucUrl || process.env.VUE_APP_ucUrl, "/login?clientId=").concat(envConfig.VUE_APP_clientId || process.env.VUE_APP_clientId, "&redirectUrl=").concat(window.location.origin, "/#/");
  } else {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    });
  }
  return Promise.reject(error);
});
export default service;