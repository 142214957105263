var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar"
  }, [_c("hamburger", {
    staticClass: "hamburger-container",
    attrs: {
      "is-active": _vm.sidebar.opened
    },
    on: {
      toggleClick: _vm.toggleSideBar
    }
  }), _vm._v(" "), _c("breadcrumb", {
    staticClass: "breadcrumb-container"
  }), _vm._v(" "), _c("div", {
    staticClass: "right-menu"
  }, [_c("el-dropdown", {
    staticClass: "avatar-container",
    attrs: {
      trigger: "click"
    },
    on: {
      command: _vm.handleCommand
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper"
  }, [_c("img", {
    staticClass: "user-avatar",
    attrs: {
      src: _vm.defaultAvatar
    }
  }), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.username ? _vm.username : ""))]), _vm._v(" "), _c("i", {
    staticClass: "el-icon-caret-bottom"
  })]), _vm._v(" "), _c("el-dropdown-menu", {
    staticClass: "user-dropdown",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "logout"
    }
  }, [_vm._v("退出")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };